import React from 'react'

const YouTubeEmbed = ({node}: any) => (
  <section className="video-block">
    {node && node.videoId ? (
      <iframe width="100%" height="600" src={`https://www.youtube.com/embed/${node.videoId}`} frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
    ) : (<p>There was an error loading this video.</p>)}
  </section>
)


export default YouTubeEmbed

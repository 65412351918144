import React from 'react'
import BasePortableText from '@sanity/block-content-to-react'
import serialisers from '../serialisers/serialisers'

const PortableText = ({blocks}) => (
  <section className={'articleStyles'}>
    <BasePortableText className={'articleStyles'} blocks={blocks} serializers={serialisers} />
  </section>
)
export default PortableText

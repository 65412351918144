import React        from 'react'
import Figure       from './Figure'
import YouTubeEmbed from './YouTubeEmbed'
import FileLink     from './FileLink'

const serialisers = {
  types: {
    authorReference: ({node}: any) => <span>{node.author.name}</span>,
    youTube: YouTubeEmbed,
    mainImage: Figure
  },
    marks: {
      file: FileLink
    }
}

export default serialisers

import React from 'react'

export default ({ node }) => {
    if (!node || !node.asset || !node.asset._id) {
        return null
    }
    return (
        <figure style={{ margin: '1rem' }}>
            <img style={{ marginRight: '1rem', float: 'right', maxWidth: '400px', width: '45%' }} src={node.asset.url}
                 alt={node.alt} />
            <figcaption>{node.caption}</figcaption>
        </figure>
    )
}

import React from 'react'

interface FileMark {
    children: string[]
    mark: {_key: string, _type: 'file', asset: { url: string; extension: string; size: number }}
}

export default (node) => {
    // console.log(node)
    if (!node || !node.mark.asset || !node.mark.asset._id) {
        return null
    }
    return (
        <a href={node.mark.asset.url}>{node.children.map((text: string) => text)}</a>
    )
}
